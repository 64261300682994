@import "theme-dark";
@import "bulma/utilities/_all";
@import "bulma/base/_all";
@import "bulma/elements/_all";
@import "bulma/form/_all";
@import "bulma/components/_all";
@import "bulma/grid/_all";
@import "bulma/layout/_all";

.screenshot {
    position: relative;
    width: 325px;
    margin-left: auto;
    margin-right: auto;
  }

  .device-overlay {
      position: relative;
    width: 325px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
  }

.screen-pic {
    position: absolute;
  width: 308px;
  height: auto;
  z-index: 0;
  padding-top: 16px;
  padding-left: 17px;
  background-color:transparent;
}

#promo-video {
    display: none;
    width: 288px;
    padding-top: 34px;
    padding-left: 37px;
}

#screens-container {
    display: block;
    padding-top: 40px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    }

    h1 {
        font-weight: 600;
        font-size: 24px;

    }
    
    h2 {
        font-size: 20px;
        font-weight: 400;
        padding-top: 20px;
    }

    h1 {
        padding-top: 20px;
        padding-bottom: 8px;
    }
    
    h1.post-title {
        padding-top: 0px;
        padding-bottom: 8px;
    }
    
    #press a {
        color: #2281EE;
    }
    
    #terms a {
        color: #2281EE;
    }
    
    #privacy a {
        color: #2281EE;
    }
    
    #press h2 {
        padding-bottom: 5px;
    }
    
    #press ul {
        list-style-type: circle;
    }
    
    #press #unlimited-price {
        padding-bottom: 5px;
        font-weight: bold;
    }
    
    #press td {
        padding: 2px;
    }