$primary: #427FE7;
$link: #000000;
$white: #FFFFFF;
$dark: #000000;
$box-color: #000000;
$footer-background-color: $white;
$navbar-item-img-max-height: 5rem;
$desktop: 900px;
$fullhd: $desktop;
$widescreen: $desktop;
$navbar-breakpoint: 0;
$navbar-item-hover-color: $primary;
$navbar-item-hover-background-color: $white;
$section-padding: 1.5rem 1rem;
$navbar-padding-horizontal: 1em;

#cta {
	margin-top: 25px;
}

#ctaMobile {
	margin-top: 25px;
}

#playButton {
	margin: 5px;
}

#joinBetaHeroButton {
	margin: 5px;
}

#speedSelection {
	margin-top: -30px;
	max-width: 900px;
	margin-left: auto;
	  margin-right: auto;
	  margin-bottom: -30px;
}

#speedSelectionStatic {
	margin-top: 0px;
}

#video {
	display: none;
	margin-top: -30px;
	margin-bottom: -30px;
	max-width: 1000px;
	margin-left: auto;
  	margin-right: auto;
}

.rounded {
	border-radius: 14px;
}

.rotatingImage {
	display: none;
}

.icon {
	margin: 10px;
}

.features {
	max-width: 900px;
	margin-left: auto;
  	margin-right: auto;
}

.spacer {
	margin-top: 20px;
}

#screens-spacer {
	display: block;
	height: 90px;
}

.screens {
	padding-left: 60px;
	padding-right: 60px;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}

.screen {
	padding: 20px;
}

.screen-item {
	display: inline-block;
}