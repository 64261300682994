@import "overrides";
/*$dark-background: #1C1C1E;*/
$dark-background: #000000;
$box-color: $white;
$text: $white;
$text-strong: $white;
$link: $white;
$link-hover: $primary;
$scheme-main: $dark-background;
$footer-background-color: $scheme-main;
$navbar-item-hover-background-color: $dark-background;